<template>
  <div class="schoolTW">
            <div class="top-box">
              <div class="top-title">台湾院校查询</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">院校名称</div>
                  <div class="input-box">
                    <el-input v-model="name" placeholder="请输入院校名称" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">所在地区</div>
                  <div class="input-box">
                    <el-input v-model="region" placeholder="请输入所在地区" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">所在县市</div>
                  <div class="input-box">
                    <el-input v-model="city" placeholder="请输入县市名称" class="form-control"></el-input>
                  </div>
              </el-col>
            </el-row>
            <!--  -->
            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe class="table-body" 
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="tableLoading">
                <el-table-column prop="logo" label="院校logo" width="80">
                   <template slot-scope="scope">
                     <img class="logo" :src="scope.row.logo"/>
                   </template>
                </el-table-column>
                <el-table-column prop="name" label="院校名称" width="160">
                </el-table-column>
                <el-table-column prop="region" label="地区" width="80">
                </el-table-column>
                <el-table-column prop="major" label="招生系所" :show-overflow-tooltip="true">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="tableTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
            <!--  -->
          </div>
</template>
<script>
export default {
  name: "schoolTW",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      tableLoading:false,
      name:"",
      region:"",
      city:"",
      tableData: [],
    };
  },
  methods: {
    initTableData(){
      this.tableLoading = true;
      var data={
          city: this.city,
          currentPage: this.pageNo,
          name: this.name,
          pageSize: this.pageSize,
          region: this.region
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getHKTShcoolByPageCondition,data).then((res) => {
        this.tableLoading = false;
        if (res.data) {
          this.tableData = res.data.list.map(r=>{
            return {
              id:r.id,
              logo: r.pic,
              name: r.name,
              region:r.region,
              major:r.subject,
              cost:r.cost,
              rule:r.enrollRules
            }
          });
          this.tableTotal = res.data.total;
        }
      });
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    }
  },
  mounted(){
    this.initTableData();
  },
};
</script>
<style lang="less" scoped>
.schoolTW {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }

  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .logo{
      height: 48px;
      width: 48px;
    }
    .label{
      display: inline-block;
      border: 1px solid #646464;
      font-size: 12px;
      margin-right: 2px;
      border-radius: 4px;
      padding: 0 5px;
      line-height: 14px;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  
}

</style>